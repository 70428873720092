.dkg-myacc-navbar{
    .Lsidebar{
        background-color: #3c97b6;
        z-index: 992;
        width: 100%;
        padding: 0 15px;
        position: fixed;
        left: 0;
        top: 54px;
        transition: all 0.25s ease-in-out;
        display: flex;
        .dflex2{
            width: 100%;
            max-width: 1600px;
            margin: 0 auto;
            position: relative;
            justify-content: space-between;
            align-items: center;
            .dkg-mynavbar-tabNav{
                // display: flex!important;
                // flex-basis: auto;
                ul{
                    margin: 25px 0 0 auto;
                    padding: 0;
                    position: relative;
                    display: flex;
                    width: 100%;
                    background-color: #1f394c;
                    border-radius: 5px 5px 0 0;
                    margin-left: 0;
                    li{
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        width: 100%;
                        max-width: 10%;
                        a{
                            margin-right: 0;
                            padding: 12px 3px;
                            display: block;
                            white-space: nowrap;
                            text-align: center;
                            font-size: 14px;
                            color: #fff;
                            position: relative;
                            // font-weight: 600;
                            border-left: 1px solid #aaa;
                            // border-top: 1px solid #aaa;
                            &.grey-active{
                                background-color: #eaeaea;
                                color: #333;
                                height: 46px;
                            }
                            &.white-active{
                                background-color: #fff;
                                color: #333;
                                height: 46px;

                            }
                            &.blue-active{
                                background-color: #eaeaea;
                                color: #333;
                                height: 46px;  
                                border-radius: 0;
                            }
                            .dkg-taskbadgecon {
                                background-color: #3c97b6;
                                border-radius: 50%;
                                color: #fff;
                                display: inline-block;
                                font-size: 9px;
                                height: 18px;
                                line-height: 18px;
                                margin: 0 5px;
                                text-align: center;
                                width: 18px;
                            }
                            
                        }
                        &:first-child {
                            a {
                                border-radius: 5px 0 0 0;
                            }
                        }
                        &:last-child{
                            a{
                                border-radius: 0 ;  
                            }
                        }
                        ul {
                            left: 0;
                            width: 100%;
                            z-index: 990;
                            border-radius: 0;
                            top: 95%;
                            display: flex;
                            opacity: 0;
                            visibility: hidden;
                            background: #fff;
                            margin: 0;
                            border-radius: 0 0 5px 5px;
                            position: absolute;
                            li {
                                margin: 0;
                                padding: 0;
                                list-style: none;
                                width: 100%;
                                max-width: 10%;
                                a{    
                                    margin-right: 0;
                                    padding: 11.7px 3px;
                                    display: block;
                                    white-space: nowrap;
                                    text-align: center;
                                    font-size: 14px;
                                    position: relative;
                                    // font-weight: 600;
                                    color: #313131;
                                    border-left: 1px solid #aaa;
                                    /* margin: 0 0 0 -1px; */
                                    border-top: 0;
                                    border-bottom: 0;
    
                                }
                                &:first-child{
                                    a{
                                        border-radius: 0 0 0 5px;
                                    }
                                }
                                &:last-child{
                                    a{
                                        border-right: 1px solid #aaa;
                                        border-radius: 0;
                                    }
                                }
                                &:nth-child(10){
                                    border-radius: 0 0 5px 0;
                                }
                            }
                            
                        }
                        &.dkg-myacc-nav-main-li{
                            &.dkg-services-li{
                                color: green;
                                .dkg-myacc-submenu-ul{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            &.dkg-profile-li{
                                color: yellow;
                                .dkg-myacc-submenu-ul{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            &.dkg-setting-li{
                                color: red;
                                .dkg-myacc-submenu-ul{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
        }
        .profile-info {
            display: none;
            figure{
                margin: 0;
                background: #dcdcdc;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 55px;
                height: 55px;
                img{
                    border-radius: 100%;
                    height: auto;
                }
            }
        }
        // .navbar-toggler {
        //     display: none;
        // }
        // .navbar-collapse{
        //     display: flex!important;
        //     flex-basis: auto;
        //     position: absolute;
        //     width: calc(100vw - 30px);
        //     height: calc(100vh - 160px);
        //     overflow: auto;
        //     top: 100%;
        //     background: rgb(0 0 0);
        //     box-shadow: 0 5px 6px rgb(255 255 255/.1);
        //     z-index: 2;
        // }
        // & > strong{
        //     text-align: center;
        //     width: calc(100vw - 113px);
        // }
        // .navbar-collapse {
        //     ul{
        //         display: block;
        //         padding: 0;
        //         margin: 0;
        //         width: 100%;
        //     }
        // }
    }
    &.dkg-rc-account-Navbar{
        .Lsidebar{
            background-color: #A43B63;
            padding: 25px 15px 0 15px ;
            // .dflex2{
            //     .dkg-mynavbar-tabNav{
            //         ul {
            //             background-color: # !important;
            //         }
            //     }
            // }
        }
    }
    // .Lsidebar>div>ul>li:last-child>a, .Lsidebar ul li ul li:last-child a {
    //     border-right: 1px solid #aaa;
    // }
    // .drp_ul2 {
    //     background: #fff !important;
    //     opacity: 0;
    //     visibility: hidden;
    // }
    // .drp3l {
    //     .drp_ul1 {
    //         & > li {
    //             & > a {
    //                 color: #313131 !important;
    //             }
    //         }
    //     }
    //     .drp_ul2 {
    //         & > li {
    //             & > a {
    //                 &.active {
    //                     background: #dcdcdc !important;
    //                     color: #313131 !important;
    //                 }
    //             }
    //         }
    //     }
    // }
    // .drp3l .drp_ul1 > li > a.active, 
    // .drp3l .drp_ul1 > li:hover > a {
    //     background: #dcdcdc !important;
    //     color: #313131 !important;
    // }
}