.dkg-trmofuse-mainCon {
    margin-top: 155px;
    background-color: #eaeaea;
    padding: 25px 15px;
    height: 100vh;
    .dkg-trmofuse-title{
        margin: 0;
        color: #243b4c;
        text-align: center;
        font-weight: 600;
        font-size: 26px;
        text-transform: uppercase;
        width: 100%;
    }
    .dkg-trms-accordion-mainCon{
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        // margin-bottom: 25px;
        .dkg-myacc-priavcy-Header{
            color: #f2f2f2;
            background-color: #243b4c;
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-radius: 5px 5px 0 0;
            text-transform: capitalize;
            .dkg-privacy-title{
                margin: 0;
                font-size: 22px;
                font-weight: 600;
                text-align: center;
                color: #f2f2f2;
            }
        }
       
        .dkg-trms-of-use-bodyCon{
            padding: 0;
            height: calc(100vh - 265px);
            overflow-y: auto;
            .dkg-screen-faq-accodionCon {
                color: #333;
                // border-bottom: 1px solid #4d5a6b;
                border: inherit !important;
    
                .dkg-screen-faq-accodion {
                    border: 1px solid #ddd;
                    border-bottom: none;
                    background-color: #fff;
                    color: #333;
                    min-height: 52px;
                    position: relative;
    
                    .MuiAccordionSummary-content {
                        p {
                            color: #333;
                            font-size: 14px;
                        }
                    }
    
                    // .dkg-screen-faq-accodion-suMM {
                    //     background-color: #eaeaea;
                    //     color: #f2f2f2;
                    //     border-bottom: 1px solid #4d5a6b;
                    //     min-height: 52px;
    
                    //     .MuiIconButton-root.MuiAccordionSummary-expandIcon {
                    //         .MuiIconButton-label {
                    //             color: #f2f2f2;
    
                    //         }
                    //     }
                    // }
    
                    // &:first-child {
                    //     .dkg-screen-faq-accodion-suMM {
                    //         border-radius: 5px 5px 0 0;
    
                    //         div {
                    //             p {
                    //                 font-size: 15px !important;
                    //             }
                    //         }
                    //     }
                    // }
    
                    // &:last-child {
                    //     .dkg-screen-faq-accodion-suMM {
                    //         border-radius: 0 0 5px 5px;
                    //     }
                    // }
    
                    // .MuiCollapse-root.MuiCollapse-entered {
                    //     .MuiCollapse-wrapper {
                    //         .MuiCollapse-wrapperInner {
                    //             div {
                    //                 .MuiAccordionDetails-root {
                    //                     background-color: #24475f;
                    //                     color: #f2f2f2;
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                    .MuiAccordionSummary-content.Mui-expanded {
                        p.MuiTypography-root.MuiTypography-body1 {
                            color: #333;
                            font-size: 14px;
                            font-family: inherit;
                            text-transform: capitalize;
                        }
                    }
    
                    .MuiAccordionSummary-expandIconWrapper.Mui-expanded,
                    .MuiAccordionSummary-expandIconWrapper {
                        position: absolute;
                        right: 10px;
    
                        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
                            color: #333;
                        }
                    }
                }
    
                &:first-child {
                    .dkg-screen-faq-accodion {
                        border-radius: 5px 5px 0 0 !important;
                    }
                }
    
                &:last-child {
                    .dkg-screen-faq-accodion {
                        border-bottom: 1px solid #ddd;
                        border-radius: 0 0 5px 5px;
                    }
                }
    
                .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
                    .MuiCollapse-wrapper.MuiCollapse-vertical {
                        .MuiCollapse-wrapperInner.MuiCollapse-vertical {
                            .MuiAccordion-region {
                                .MuiAccordionDetails-root {
                                    background-color: #f2f2f2;
                                    border-radius: 0 0 5px 5px;
    
                                    p.MuiTypography-root.MuiTypography-body1 {
                                        color: #333;
                                    }
    
                                    textarea.dkg-faq-answertextArea {
                                        border: none;
                                        outline: 0;
                                        box-shadow: none;
                                        color: #333;
                                        background-color: #f2f2f2 !important;
                                        font-size: 14px;
                                        resize: none;
                                        border: none !important;
                                        width: 100% !important;
                                        cursor: text;
                                        caret-color: #f2f2f2;
    
                                        // min-height: 180px;
                                        // max-height: 190px;
                                        // overflow-y: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}