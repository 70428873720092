.dkg-myacc-noti-mainpageCon{
    margin-top: 152px;
    height: 100vh;
    background-color: #eaeaea;
    width: 100%;
    padding: 15px 25px;
    .dkg-myacc-noti-mainRow{
        margin-top: 15px;
        .dkg-myacc-noti-mainCol{
            .dkg-myacc-noti-subRow{
                .dkg-myacc-noti-leftCon{
                    .dkg-reset-infoLeftCard{
                        background-color: #fff;
                        border-left: 5px solid #9d265c;
                        border-radius: 0.25rem;
                        padding: 1.25rem;
                        p{
                            font-size: 14px;
                        }
                    }
                }
                .dkg-myacc-noti-rightCon{
                    .dkg-myacc-noti-cardbody{
                        position: relative;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-direction: column;
                        flex-direction: column;
                        min-width: 0;
                        word-wrap: break-word;
                        background-color: #fff;
                        background-clip: border-box;
                        border: 1px solid rgba(0,0,0,.125);
                        border-radius: 0.25rem;
                        padding: 0;
                        background-color: #fff;
                        .dkg-myacc-noti-title{
                            background-color: #1f394c;
                            padding: 0 10px;
                            color: #fff;
                            font-size: 18px;
                            font-weight: bold;
                            text-align: center;
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                            height: 45px;
                            line-height: 45px;
                            margin-bottom: 0;
                        }
                        .Em-tx{
                            color: #8a8a8a;
                            width: 100%;
                            text-align: center;
                            padding: 1.15rem 15px;
                            background-color: #f2f2f2;
                            border-bottom: 1px solid #ddd;
                            margin-bottom: 0 !important;
                            font-size: 0.95rem;
                        }
                        .dkg-noti-list-group{
                            display: -ms-flexbox;
                            display: flex;
                            -ms-flex-direction: column;
                            flex-direction: column;
                            padding-left: 0;
                            margin-bottom: 0;
                            background-color: #fff;
                            padding: 0.75rem 1.25rem;
                            border-radius: 5px;
                            border: none;
                            margin: 0 !important;
                            .dkg-noti-list-Row{
                                .dkg-noti-list-Col{
                                   .dkg-text-dark{
                                    color: #343a40;
                                    font-weight: bold;
                                    font-size: 1.25rem;
                                   }
                                }
                            }
                            .dkg-noti-list{
                                position: relative;
                                display: block;
                                border: none !important;
                                margin-left: 20px;
                                padding-bottom: 10px;
                                color: #0070C0;
                                list-style-type: square;
                                .d-flex{
                                    .dkg-noti-message{
                                        color: #333;
                                    }
                                    .dkg-feature-column{
                                        .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
                                            left: 100%;
                                            transform: translateX(-100%);
                                            background-color: #3a9469 !important;
                                        }
                                        .dkg-off-label{
                                            &:first-child{
                                                color: #d91010;
                                            }
                                            &:last-child{
                                                color: #3a9469;
                                            }
                                        }
                                    }
                                    .dkg-active-btnCon{
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 220px;
                                        .dkg-active-btn{
                                            display: inline-block;
                                            background-color: #4BBC67;
                                            color: #fff;
                                            padding: 0px 1px;
                                            font-size: 13px;
                                            border-radius: 25px;
                                            text-transform: capitalize;
                                            width: 80px;
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .dkg-myfeedback-formCon{
                            .dkg-feedback-card{
                                display: flex;
                                flex-direction: column;
                                padding-left: 0;
                                margin-bottom: 0;
                                background-color: #fff;
                                padding: 0.75rem 1.25rem;
                                border-radius: 5px;
                                border: none;
                                margin: 0;
                                .dkg-feedback-cardRow{
                                    .dkg-feedback-cardCol{
                                        form{
                                            padding: 15px 20px;
                                            .form-group{
                                                margin-bottom: 1rem;
                                                .form-control{
                                                    height: 34px;
                                                    padding: 0.1rem 0.5rem;
                                                    font-size: 14px;
                                                    background-color: #f2f2f2;
                                                    line-height: 1.3;
                                                    border: #ddd 1px solid;
                                                    text-align: left;
                                                }
                                                textarea.form-control {
                                                    height: 80px;
                                                    text-align: left;
                                                }
                                                &.dkg-feedback-frmgrp{
                                                    display: flex;
                                                    align-items: center;
                                                    .dkg-feedback-label{
                                                        font-size: 13px;
                                                        min-width: 135px;
                                                        display: flex;
                                                        align-items: center;
                                                    }
                                                }
                                            }
                                            .dkg-priacy-trmsCon{
                                                p{
                                                    width: 100%;
                                                    text-align: center;
                                                    font-size: 14px;
                                                    color: #333;
                                                    input[type="checkbox"] {
                                                        cursor: pointer;
                                                        appearance: auto;
                                                        background: #fff;
                                                        border-radius: 0.25rem;
                                                        box-sizing: border-box;
                                                        position: relative;
                                                        width: 14px;
                                                        height: 14px;
                                                        border-width: 0;
                                                        transition: all 0.3s linear;
                                                        border: 1px solid #ddd;
                                                        margin-right: 0.5rem;
                                                        border-radius: 0;
                                                    }
                                                    a{
                                                        margin: 0 0.5rem;
                                                    }
                                                }
                                            }
                                            .dkg-feedback-btnCon{
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 100%;
                                                margin-top: 10px;
                                                .dkg-feedback-btn{
                                                    background-color: #1f394c  !important;
                                                    border-color: #1f394c  !important;
                                                    color: #fff !important;
                                                    border-radius: 0.25rem;
                                                    &:hover{
                                                        background-color: #2e96b6 !important;
                                                        border-color: #2e96b6 !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .dkg-mycookies-mainCon{
                            display: -ms-flexbox;
                            display: flex;
                            -ms-flex-direction: column;
                            flex-direction: column;
                            padding-left: 0;
                            margin-bottom: 0;
                            background-color: #fff;
                            padding: 0.75rem 1.25rem;
                            border-radius: 5px;
                            // border: 1px solid #ddd;
                            margin: 0;
                            .dkg-mycookies-li{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                list-style: none;
                                width: 100%;
                                padding: 15px 12px;
                                .dkg-mycookies-li-left{
                                    width: 75%;
                                    .dkg-cookies-title{
                                        font-size: 1.15rem;
                                        font-weight: bold;
                                    }
                                    .dkg-cookies-desc{
                                        font-size: 1rem;
                                    }
                                }
                                .dkg-mycookies-li-right{
                                    width: 25%;
                                  .dkg-cookies-container{
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                    .dkg-cookies-switch{
                                        position: relative;
                                        width: 180px;
                                        height: 37px;
                                        display: block;
                                        margin: auto;
                                        margin-bottom: auto;
                                        // .dkg-cookies-toggle-vat{

                                        // }
                                        input{
                                            display: none;
                                        }
                                        .dkg-slider{
                                            position: absolute;
                                            cursor: pointer;
                                            top: 0;
                                            left: 0;
                                            right: 0;
                                            bottom: 0;
                                            background-color: #fff;
                                            -webkit-transition: .4s;
                                            transition: .4s;
                                            border: 1px solid #4BBC67;
                                            &::before {
                                                position: absolute;
                                                content: "";
                                                height: 100%;
                                                width: 50%;
                                                left: 0;
                                                bottom: 0;
                                                background-color: #4BBC67;
                                                -webkit-transition: .4s;
                                                transition: .4s;
                                                border-top-left-radius: 25px;
                                                border-bottom-left-radius: 25px
                                              }
                                            &.round {
                                                border-radius: 25px;
                                              }

                                            input {
                                                display: none;
                                              }
                                            .dkg-cookies-slider-text{
                                                position: absolute;
                                                transform: translate(-50%,-50%);
                                                top: 50%;
                                                left: 50%;
                                                font-size: 14px;
                                                width: 100%;
                                                text-align: center;
                                                span {
                                                    color: #fff;
                                                    font-size: 14px;
                                                    width: 50%;
                                                    display: block;
                                                    float: left;
                                                    -webkit-transition: .4s;
                                                    transition: .4s;
                                                }
                                            }
                                           }
                                        input:checked + .dkg-slider::before {
                                            -webkit-transform: translateX(80px);
                                            -ms-transform: translateX(80px);
                                            transform: translateX(80px);
                                            border-top-right-radius: 25px;
                                            border-bottom-right-radius: 25px
                                          }

                                          input:checked + .dkg-slider .dkg-cookies-off{
                                            color: #333;
                                          }

                                          input:checked + .dkg-slider .dkg-cookies-on {
                                            color: #fff;
                                          }

                                          .dkg-slider .dkg-cookies-slider-text .dkg-cookies-on {
                                            color: #4BBC67;
                                          }
                                    }
                                    &.dkg-active-btnCon{
                                        justify-content: flex-end;
                                        .dkg-active-btn{
                                            display: inline-block;
                                            background-color: #4BBC67;
                                            color: #fff;
                                            padding: 7px 10px;
                                            font-size: 14px;
                                            border-radius: 25px;
                                            text-transform: capitalize;
                                            width: 80px;
                                        }
                                    }
                                    .dkg-cookies-column{
                                        .dkg-up-in-toggle {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            input {
                                              clip: rect(0, 0, 0, 0);
                                              height: 1px;
                                              width: 1px;
                                              border: 0;
                                              opacity: 0;
                                              overflow: hidden;
                                              &:checked + label {
                                                background-color: #4BBC67;
                                                border: 1px solid #4BBC67;
                                                color: #fff;
                                                font-weight: 500;
                                                &.dkg-active-label,
                                                &.activClass {
                                                    background-color: #4BBC67;
                                                    border: 1px solid #4BBC67;
                                                    color: #fff;
                                                }
                                                &.dkg-inactive-label,
                                                &.inActivClass {
                                                    background-color: #e2445b;
                                                    border: 1px solid #e2445b;
                                                    color: #fff;
                                                }

                                              }
                                              #dkg-switch-left{
                                                &:checked + label{
                                                    background-color: #4BBC67;
                                                    border: 1px solid #4BBC67;

                                                }
                                              }
                                            //   &:checked + label.dkg-inactive-label {
                                            //     background-color: #e2445b;
                                            //     border: 1px solid #e2445b;
                                            //     color: #fff;
                                            //     font-weight: 500;
                                            //   }
                                            }
                                            label {
                                              display: inline-block;
                                              width: 80px;
                                              background-color: #fff;
                                              color: #333;
                                              text-align: center;
                                              font-size: 0.8rem;
                                              padding: 7px 10px;
                                              font-size: 14px;
                                              border: 1px solid #ddd;
                                              transition: all 0.1s ease-in-out;
                                              &:first-child{
                                                border-right: none;
                                              }
                                              &:last-child{
                                                border-left: none;
                                              }
                                              &:hover {
                                                cursor: pointer;
                                              }
                                              &.activClass {
                                                background-color: #4BBC67;
                                                border: 1px solid #4BBC67;
                                                color: #fff;
                                              }
                                              &.inActivClass {
                                                background-color: #e2445b;
                                                border: 1px solid #e2445b;
                                                color: #fff;
                                              }
                                            }
                                            label:first-of-type {
                                                border-radius: 15.5px 0 0 15.5px;
                                            }
                                            label:last-of-type {
                                                border-radius: 0 15.5px 15.5px 0;
                                            }
                                        }
                                    }
                                  }
                                }
                            }
                        }
                    }
                    .dkg-myacc-noti-cardfooter{
                        width: 100%;
                        background-color: #fff;
                        padding: 1.25rem;
                        .dkg-cookies-btnCon{
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            .dkg-saved-cookies-btn{
                                display: inline-block;
                                padding: 9px 10px;
                                font-size: 14px;
                                background-color: #eaeaea;
                                color: #333;
                                text-transform: capitalize;
                                margin: 0;
                                border-radius: 5px;
                            }
                            .dkg-accept-cookies-btn{
                                display: inline-block;
                                padding: 9px 10px;
                                font-size: 14px;
                                background-color: #4BBC67;
                                color: #fff;
                                text-transform: capitalize;
                                margin: 0;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}