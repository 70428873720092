.dkg-myacc-jobprofile-main-Con {
    margin-top: 110px;
    background-color: #eaeaea;
    padding: 25px 15px;
    height: 100%;
    // height: calc(100vh - 125px);
    .dk-cliBoardViewPageCont{
        margin-top: 0 !important;
        padding: 0;
        .dk-qulaifyTopHead{
            .dk-qulaifyLeftTopHead{
                .dk-SrchInputBox{
                    .input-group{
                        .input-group-append{
                            button{
                                background-color: #b59ca4 !important;
                                i{
                                    color: #fff !important;
                                }
                            }
                        }
                    }
                }
            }
            .dk-qulaifyRightTopHead {
                .dkg-jobsfaq-tab{
                    background-color: #fff;
                }
            }
        }
        .dk-jobboardTableCon{
            height: calc(100vh - 205px) !important;
            overflow-y: auto;
        }
    }
}