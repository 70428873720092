.dkg-myacc-priavcy-Con {
    margin-top: 155px;
    background-color: #eaeaea;
    padding: 25px 15px;
    height: calc(100vh - 165px);
    .dkg-myacc-priavcy-mainRow{
        .dkg-myacc-priavcy-mainCol{
            .dkg-myacc-privacy-cardCon{
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius: 5px;
                width: 100%;
                max-width: 1600px;
                margin: 0 auto;
                .dkg-myacc-priavcy-Header{
                    color: #fff;
                    background-color: #243b4c;
                    padding: 0.75rem 1.25rem;
                    margin-bottom: 0;
                    border-radius: 5px 5px 0 0;
                    .dkg-privacy-title{
                        margin: 0;
                        font-size: 22px;
                        font-weight: 600;
                        text-align: center;
                        color: #fff;
                    }
                }
                .dkg-myacc-priavcy-Body{
                    padding: 1.25rem;
                    height: calc(100vh - 265px);
                    overflow-y: auto;

                    .dkg-privacy-subtitle{
                        font-size: 15px;
                        text-transform: uppercase;
                        margin-bottom: 1rem;
                        color: #4d4a4a;
                        font-weight: bold;

                    }
                    .dkg-privacy-paragrph{
                        font-size: 14px;
                        text-align: justify;
                        color: #4d4a4a;
                        .dkg-privacy-bold-text{
                            font-weight: bold;
                        }
                    }
                }
            }

        }
    }

}