// .productbox {
//     display: flex;
//     background-color: grey;
//     color: #797474;
//     content: "\f0d7";
//     font-family: "Dropdown";
//     position: absolute;
//     right: 13px;
//     font-weight: 400;
//     opacity: 0.9;
//     &::after {
//         color: #797474;
//         content: "\f0d7";
//         font-family: "Dropdown";
//         position: absolute;
//         right: 13px;
//         font-weight: 400;
//         opacity: 0.9;
//     }
//   }
  
//   .notdisplayed {
//     display: none;
//   }
  
//   .displayed {
//     display: flex;
//     height: 20px;
//     margin: auto;
//   }
//   .dk-prerightpopoverCon{
//     position: relative;
    .dkg-infoicon{
        margin-left: 8px;
        position: relative;
        svg{
            width: 20px;
            height: 20px;
            cursor: pointer;
    
        }
        .notdisplayed {
            display: none;
        }
        .displayed {
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            position: absolute;
            top: -9px;
            left: 35px;
            background-color: #396A95;
            color: #fff;
            padding: 8px;
            border: 1px solid transparent;
            box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.20), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
            border-radius: 4px;
            width: 420px;
            height: 120px;
            p{
                font-size: 12px;
                text-transform: capitalize;
                font-weight: normal;
            }
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-color: transparent #396A95 transparent transparent;
                border-style: solid;
                border-width: 8.5px 12px 8.5px 0;
                line-height: 0;
                _border-color: #000 #396A95 #000 #000;
                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                position: absolute;
                left: -12px;
                top: 10px;
            }
        }
    }
//   }