.dk-composeMailModal {
    &.dkg-myacc-composeModal{
        .modal-dialog{
            max-width: 90%;
            .modal-content{
                .dkg-myacc-composeModalHeader{
                    .close{
                        width: 25px;
                        height: 34px;
                        &:hover{
                            color: #333;
                            background-color: #eaeaea;
                        }
                    }
                    &.dkg-myacc-newHeader{
                        position: relative;
                        width: 100%;
                        .dk-composeMail-option{
                            position: absolute;
                            left: 10px;
                            .dk-composeMail-PanelCols{
                                display: flex;
                                &:last-child{
                                    .dk-mailAttach-file{
                                        margin-right: 0;
                                    }
                                }
                            }
                            .dk-mailAttach-file{
                                position: relative;
                                text-align: center;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 10px;
                                &:hover{
                                    color: #2e97b6;
                                    span{
                                        color: #2e97b6;
                                    }
                                }
                                .custom-file-label.custom.custom-file{
                                    position: relative;
                                    margin: 0 !important;
                                    height: auto;
                                    background: transparent;
                                    border: none;
                                    padding-left:5px;
                                    &:hover{
                                        color: #2e97b6;
                                    }
                                    &::after{
                                        display: none;
                                    }
                                    // &::before{
                                    //     content: '';
                                    //     background: url('../../../../assets/images/attachment.png') no-repeat;
                                    //     padding: 10px;
                                    //     background-size: 14px;
                                    //     position: absolute;
                                    //     left: 2px;
                                    // }
                                }
                                .custom-file-label{
                                    height: auto;
                                    padding: 0;
                                    position: relative;
                                    border: none;
                                    background: transparent;
                                    margin-bottom: 0;
                                    color: #333;
                                    white-space: nowrap;
                                    line-height: 37px;
                                    padding-left: 5px;
                                    outline: none;
                                    box-shadow: none !important;
                                    font-size: 13px;
                                    font-weight: 400;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: row-reverse;
                                    cursor: pointer;
                                    z-index: 99;
                                    &:hover{
                                        color: #2e97b6;
                                        &::after{
                                            color: #2e97b6;
                                        }
                                    }
                                    &::after{
                                        content: 'Attach File';
                                        display: block;
                                        right: initial;
                                        left: -3px;
                                        font-size: 16px;
                                        font-weight: 500;
                                        color: #fff;
                                        height: auto;
                                        padding: 0;
                                        border-radius: 0;
                                        position: relative;
                                        top: 0;
                                        cursor: pointer;
                                        background-color: transparent;
                                        &:hover{
                                            color: #2e97b6;
                                        }
                                    }
                                }
                                input{
                                    width: 100%;
                                    height: 40px;
                                    opacity: 0;
                                    position: absolute;
                                    top: -2px;
                                    z-index: 9;
                                    cursor: pointer;
                                    &:hover{
                                        color: #2e97b6;
                                    }
                                }
                                span{
                                    position: relative;
                                    z-index: 1;
                                    font-weight: 500;
                                    font-size: 15px;
                                    color: #fff;
                                    cursor: pointer;
                                    &:hover{
                                        color: #2e97b6;
                                    }
                                }
                                a{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-weight: 500;
                                    font-size: 15px;
                                    height: auto;
                                    color: #333;
                                    svg{
                                        display: inline-block;
                                        margin-right:7px;
                                    }
                                    &:hover{
                                        color: #2e97b6;
                                    }
                                }
                                button{
                                    background: transparent !important;
                                    box-shadow: none !important;
                                    outline: none;
                                    min-width: auto;                                
                                    span{
                                        .MuiButton-startIcon{
                                            display: none;
                                        }
                                    }
                                }
                            }
                            .dk-mailAttach-file.dk-sendBtn{
                                background: #cd5360;
                                color: #fff;
                                border-radius: 5px;
                                margin-top:0;
                                position: absolute;
                                left: 50%;
                                transform: translate(-50%);                            
                                top: 15px;
                                button{
                                    padding: 5px 17px;
                                    padding-left: 35px;
                                    &:hover{
                                        background: #27597e !important;
                                        transition: all 0.5s;
                                        
                                    }
                                    .MuiButton-label{
                                        color: #fff;
                                    }
                                }
                                svg{
                                    display: inline-block;
                                    position: absolute;
                                    left: 13px;
                                    z-index: 9;
                                }
                            }
                        }
                    }
                }
                .dkg-myacc-composeModalBody{
                    .dkg-sendemail-boxCon{
                        .dk-sendMail-btn{
                            display: inline-block;
                            width: 100%;
                            max-width: 65px;
                            margin-left: 5px;
                            margin-top: 30px;
                            button{
                                display: inline-block;
                                width: 100%;
                                background: #3a688d;
                                border:1px solid #3a688d;
                                outline: none;
                                color: #fff;
                                font-size: 13px;
                                height:85px;
                                text-align: center;
                                text-transform: uppercase;
                                span{
                                    display: block;
                                    width: 100%;
                                    text-align: center;
                                    line-height: 15px;
                                    svg{
                                        opacity: 1;
                                        font-size: 15px;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                        .dk-mailField{
                            width: 100%;
                            .dk-mailSendOption{
                                display: flex;
                                align-items: center;
                                position: relative;
                                .dk-mailsTagMain{
                                    position: absolute;
                                    left: 90px;
                                    top: 4px;
                                    display: flex;
                                    flex-wrap: wrap;
                                    .dk-mailtags{
                                        font-size: 11px;
                                        background: #f2f2f2;
                                        border-radius: 30px;
                                        padding: 0px 10px;
                                        font-weight: 400;
                                        position: relative;
                                        margin-right: 3px;
                                        span{
                                            display: inline-block;
                                            font-size: 11px;
                                            margin-left: 5px;
                                            cursor: pointer;
                                            position: relative;
                                            top: 1px;
                                        }
                                    }
                                }
                                .dk-mailsCategory{
                                    width: 100%;
                                    // max-width: 150px;
                                    display: flex;
                                    .dropdown{
                                        width: 100%;
                                        .dropdown-toggle{
                                            box-shadow: none;
                                            border: none;
                                            outline: none;
                                            text-transform: inherit;
                                            font-size: 13px !important;
                                            background: #fff;
                                            padding: 0;
                                            height:30px;
                                            width: 100%;
                                            max-width: 180px;
                                            margin: 0;
                                            border: 1px solid #ccc;
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            padding: 0 10px;
                                            margin-left: 5px;
                                        }
                                        .dropdown-menu{
                                            border: 1px solid #ddd;
                                            border-radius: 0;
                                            margin: 0;
                                            transform: inherit !important;
                                            top: 29px !important;
                                            min-width: 180px;
                                            max-width: 180px;
                                            // width: 100%;
                                            padding: 0;
                                            a{
                                                display: block;
                                                padding:5px 10px;
                                                font-size: 13px;
                                                border-bottom: 1px solid #ddd;
                                                &:last-child{
                                                    border-bottom: none;
                                                }
                                            }
                                        }
                                    }
                                    &.dkg-disable-category{
                                        position: relative;
                                        top: -2px;
                                        .dropdown{
                                            .dropdown-toggle{
                                                background-color: transparent;
                                                &::after{
                                                    display: none;
                                                }
                                            }  
                                        }
                                    }
                                }
                                select{
                                    border: 1px solid #ccc;
                                    outline: none;
                                    width: 100%;
                                    max-width: 80px;
                                    text-align: center;
                                    font-size: 13px;
                                    height: 25px;
                                    margin-bottom: 0;
                                }
                                input{
                                    width: 100%;
                                    border:1px solid #ccc !important;
                                    outline: none;
                                    box-shadow: none !important;
                                    border-radius: 0;
                                    height:30px;
                                    margin-bottom: 5px;
                                    margin-left: 5px;
                                    text-align: left;
                                    padding: 0 2px;
                                }
                                input.readonly{
                                    border:none !important;
                                    pointer-events: none;
                                    background: transparent;
                                }
                                .dk-mailOption-items{
                                    border: 1px solid #ccc;
                                    outline: none;
                                    margin-bottom: 5px;
                                    width: 100%;
                                    max-width: 80px;
                                    text-align: center;
                                    font-size: 13px;
                                    height:30px;
                                    line-height: 30px;
                                    font-weight:400;
                                    background: #fff;
                                }
                                .dk-mailOption-items.br-none{
                                    border:none;
                                    background: transparent;
                                }
                                .dk-mailOption-items.attachFiles{
                                    background: #f2f2f2;
                                    border: 1px solid #ccc;
                                    position: relative;
                                    &:hover{
                                        background: #fff;
                                        transition: all 0.5s;
                                    }
                                    input{
                                        width: 80px;
                                        cursor: pointer;
                                        margin: 0;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        z-index: 9;
                                        opacity: 0;
                                    }
                                    
                                }
                                .dk-attachFile-cols{
                                    display: flex;
                                    align-items: center;
                                    position: absolute;
                                    right: 0;
                                    .attachItems{
                                        background: #fff;
                                        border:1px solid #ccc;
                                        border-radius: 3px;
                                        display: flex;
                                        align-items: center;
                                        position: relative;
                                        color: #333;
                                        font-size: 12px;
                                        font-weight: 400;
                                        padding: 2px 10px;
                                        margin-right: 5px;
                                        padding-right:6px;
                                        &:last-child{
                                            margin-right: 0;
                                        }
                                        span{
                                            display: inline-block;
                                            width: 12px;
                                            height: 12px;
                                            border-radius: 50%;
                                            color: #000;
                                            text-align: center;
                                            line-height: 12px;
                                            font-size: 12px;
                                            margin-left: 5px;
                                            position: relative;
                                            z-index: 9;
                                            cursor: pointer;
                                        }
                                    }
                                }
    
                                .m-lb-5x {
                                    margin: 0 0 5px 5px;
                                }
    
                                .dk-autocomplete-input {
                                    padding: 0;
    
                                    .MuiOutlinedInput-root {
                                        padding: 0;
    
                                        .MuiChip-root  {
                                            height: 22px !important;
                                            svg {
                                                width: 1rem !important;
                                            }
                                        }
    
                                        input {
                                            padding-left: 6px;
                                        }
                                    }
    
                                    input {
                                        margin: 0;
                                        color: #495057;
                                        font-size: 13px;
                                        width: auto;
                                        border: none !important;
                                    }
                                }
                                &:first-child{
                                    input{
                                        background-color: transparent;
                                        border: none !important;
                                    }
                                }
                            }
                            .dkg-cand-mail-mainCon{
                                display: flex;
                                width: 100%;
                                .dkg-cand-mail-leftCon{
                                    flex: 0 0 50%;
                                    max-width: 50%;
                                    
                                }
                                .dkg-cand-mail-rightCon{
                                    flex: 0 0 50%;
                                    max-width: 50%;
                                    .dkg-cand-mail-attachtment-ul{
                                        width: 100%;
                                        height: 104px;
                                        overflow-y: auto;
                                        .dkg-cand-mail-attachtment-li{
                                            display: flex;
                                            align-items: center;
                                            list-style: none;
                                            border: 1px solid #ddd;
                                            background-color: #f2f2f2;
                                            // padding: 3px 8px;
                                            margin: 3px 0;
                                            .dkg-file-typeicon{
                                                padding: 3px 8px;
                                                border-right: 1px solid #ddd;
    
                                                i{
                                                    // padding: 5px;
                                                    font-size: 14px;
                                                    color: #333;
                                                }
                                            }
                                            .dkg-cand-mail-file{
                                                display: flex;
                                                align-items: center;
                                                width: 100%;
                                                .dkg-cand-mail-file-name{
                                                    flex: 0 0 80%;
                                                    max-width: 80%;
                                                    padding: 0 7px;
                                                    
                                                }
                                                .dkg-cand-mail-cross-icon{
                                                    flex: 0 0 10%;
                                                    max-width: 10%;
                                                    text-align: center;
                                                    padding: 3px 5px;
                                                    border-left: 1px solid #ddd;
                                                    border-right: 1px solid #ddd;
                                                    &:last-child{
                                                        border-left: none;
                                                        border-right: none;
    
                                                    }
                                                    i{
                                                        color: #333;
                                                        cursor: pointer;
                                                        font-size: 14px;
                                                        
                                                    }
                                                    &:hover{
                                                        // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                                                        i{
                                                            font-size: 12px;
                                                            color: #848484;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .dk-textCompose-mail{
                            position: relative;
                            .dk-mailAttach-file{
                                position: absolute;
                                top: 7px;
                                right: 15px;
                                z-index: 9;
                                a{
                                    font-weight: 500;
                                    font-size: 14px;
                                    text-transform: uppercase;
                                    color: #333;
                                }
                                .dropdown{
                                    .dropdown-toggle{
                                        padding: 0;
                                        box-shadow: none;
                                        border: none;
                                        margin: 0;
                                        text-transform: inherit;
                                        font-size: 14px;
                                        &::after{
                                            display:none;
                                        }
                                    }
                                    .dropdown-menu{
                                        transform: inherit !important;
                                        right: -14px !important;
                                        left: inherit !important;
                                        margin: 0 !important;
                                        top: 27px !important;
                                        border: none;
                                        padding: 0;
                                        border-radius: 0;
                                        box-shadow: 0 0 4px rgba(0,0,0,0.13);
                                        a{
                                            display: block;
                                            text-transform: inherit;
                                            padding: 5px 10px;
                                            border-bottom: 1px solid #ddd;
                                            font-weight: normal;
                                            font-size: 14px;
                                            &:hover{
                                                background: #f2f2f2;
                                                transition: all 0.5s;
                                            }
                                            &:last-child{
                                                border-bottom: none;
                                            }
                                        }
                                    }
                                }
                            }
                            .ck-editor{
                                .ck-content{
                                    height: calc(100vh - 333px);
                                    overflow-y: auto;
                                    outline: none;
                                    box-shadow: none;
                                    border:1px solid #ccc !important;
                                    font-weight: 400;
                                }
                                .ck-sticky-panel{
                                    .ck-toolbar__items{
                                        .ck-button{
                                            font-size:10px;
                                        }
                                    }
                                }
                            }
                            .dk-send-email-btnCon{
                                position: relative;
                                top: 45px;
                                button{
                                    padding: 5px 17px;
                                    background: #cd5360;
                                    color: #fff;
                                    border-radius: 5px;
                                    &:hover{
                                        background: #27597e !important;
                                        transition: all 0.5s;
                                        
                                    }
                                    .MuiButton-label{
                                        color: #fff;
                                    }
                                }
                            }
                            .dkg-mailbox-editor-mainCon{
                                max-height: inherit !important;
                                margin: 0 !important;
                                .jodit-react-container{
                                    .jodit-container{
                                        height: calc(100vh - 380px) !important;
                                    }
                                }
                            }
                            .dkg-reply-editor-con234{
                                .dkg-mailbox-editor-mainCon{
                                    max-height: inherit !important;
                                    height: calc(100vh - 380px) !important;
                                    margin: 0 !important;
                                    .jodit-react-container{
                                        .jodit-container{
                                            height: calc(100vh - 395px) !important;
                                            overflow-y: auto;
                                        }
                                    }
                                } 
                            }
                            .dkg-send-btnCon{
                                div{
                                    div{
                                        .dkg-reply-send-btn{
                                            padding: 5px 17px;
                                            background: #cd5360;
                                            color: #fff;
                                            border-radius: 5px;
                                            &:hover{
                                                background: #27597e !important;
                                                transition: all 0.5s;
                                                
                                            }
                                            .MuiButton-label{
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .dk-composeMail-option{
                            display: block;
                            padding: 0;
                            position: relative;
                            .dk-composeMail-PanelCols{
                                display: flex;
                                &:last-child{
                                    .dk-mailAttach-file{
                                        margin-right: 0;
                                    }
                                }
                            }
                            .dk-mailAttach-file{
                                position: relative;
                                text-align: center;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 10px;
                                &:hover{
                                    color: #2e97b6;
                                    span{
                                        color: #2e97b6;
                                    }
                                }
                                .custom-file-label.custom.custom-file{
                                    position: relative;
                                    margin: 0 !important;
                                    height: auto;
                                    background: transparent;
                                    border: none;
                                    padding-left:5px;
                                    &:hover{
                                        color: #2e97b6;
                                    }
                                    &::after{
                                        display: none;
                                    }
                                    // &::before{
                                    //     content: '';
                                    //     background: url('../../../../assets/images/attachment.png') no-repeat;
                                    //     padding: 10px;
                                    //     background-size: 14px;
                                    //     position: absolute;
                                    //     left: 2px;
                                    // }
                                }
                                .custom-file-label{
                                    height: auto;
                                    padding: 0;
                                    position: relative;
                                    border: none;
                                    background: transparent;
                                    margin-bottom: 0;
                                    color: #333;
                                    white-space: nowrap;
                                    line-height: 37px;
                                    padding-left: 5px;
                                    outline: none;
                                    box-shadow: none !important;
                                    font-size: 13px;
                                    font-weight: 400;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: row-reverse;
                                    cursor: pointer;
                                    z-index: 99;
                                    &:hover{
                                        color: #2e97b6;
                                        &::after{
                                            color: #2e97b6;
                                        }
                                    }
                                    &::after{
                                        content: 'Attach File';
                                        display: block;
                                        right: initial;
                                        left: -3px;
                                        font-size: 16px;
                                        font-weight: 500;
                                        color: #333;
                                        height: auto;
                                        padding: 0;
                                        border-radius: 0;
                                        position: relative;
                                        top: 0;
                                        cursor: pointer;
                                        &:hover{
                                            color: #2e97b6;
                                        }
                                    }
                                }
                                input{
                                    width: 100%;
                                    height: 40px;
                                    opacity: 0;
                                    position: absolute;
                                    top: -2px;
                                    z-index: 9;
                                    cursor: pointer;
                                    &:hover{
                                        color: #2e97b6;
                                    }
                                }
                                span{
                                    position: relative;
                                    z-index: 1;
                                    font-weight: 500;
                                    font-size: 15px;
                                    color: #333;
                                    cursor: pointer;
                                    &:hover{
                                        color: #2e97b6;
                                    }
                                }
                                a{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-weight: 500;
                                    font-size: 15px;
                                    height: auto;
                                    color: #333;
                                    svg{
                                        display: inline-block;
                                        margin-right:7px;
                                    }
                                    &:hover{
                                        color: #2e97b6;
                                    }
                                }
                                button{
                                    background: transparent !important;
                                    box-shadow: none !important;
                                    outline: none;
                                    min-width: auto;                                
                                    span{
                                        .MuiButton-startIcon{
                                            display: none;
                                        }
                                    }
                                }
                            }
                            .dk-mailAttach-file.dk-sendBtn{
                                background: #cd5360;
                                color: #fff;
                                border-radius: 5px;
                                margin-top:0;
                                position: absolute;
                                left: 50%;
                                transform: translate(-50%);                            
                                top: 15px;
                                button{
                                    padding: 5px 17px;
                                    padding-left: 35px;
                                    &:hover{
                                        background: #27597e !important;
                                        transition: all 0.5s;
                                        
                                    }
                                    .MuiButton-label{
                                        color: #fff;
                                    }
                                }
                                svg{
                                    display: inline-block;
                                    position: absolute;
                                    left: 13px;
                                    z-index: 9;
                                }
                            }
                        }
                    }    
                }
            }
        }
    }
}